import React from "react";
import { Login } from "../components/Login";
import { NavBar } from "../components/LandingPage/NavBar";
import { getStorage } from "../utils/storage";

class LoginScreen extends React.Component {
  render() {
    return (
      <div>
        <NavBar />
        <Login />
      </div>
    );
  }
}

export default LoginScreen;
