import axios from "axios";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CardCount } from "../../components/CardCount";
import { Header } from "../../components/Header";
import { ENDPOINT } from "../../constants/api";
import { getStorage } from "../../utils/storage";
import toastMessage from "../../utils/toastMessage";

class Home extends React.Component {
  state = {
    user: {},
    total_bins: 0,
    total_collections: 0,
    total_upcoming_schedules: 0,
    route: "",
    total_waste_collected: 0,
    users: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    this.getBins();
    this.getServerSchedules(true);
    this.getPendingSchedules(true);
    this.getTotalWasteCollected(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getBins();
      this.getServerSchedules(true);
      this.getPendingSchedules(true);
      this.getTotalWasteCollected(true);
    }
  }

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let request_body = {
      page,
      limit,
    };

    if (filters && filters.locations && filters.locations.length > 0) {
      request_body.sectors = filters.locations;
    }

    if (
      filters &&
      (filters.level_min || filters.level_min === 0) &&
      filters.level_max
    ) {
      request_body.start_level_percentage =
        filters.level_min !== "" ? filters.level_min : undefined;

      request_body.end_level_percentage =
        filters.level_max !== "" ? filters.level_max : undefined;
    }

    if (filters && filters.just_collected) {
      request_body.just_collected = true;
    }

    if (filters && filters.types) {
      request_body.types = filters.types.length > 0 ? filters.types : undefined;
    }

    if (filters && filters.start_date && filters && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }
    return request_body;
  }

  getServerSchedules(isLoading) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.status = "served";
    request_body.tag = "count";
    request_body.start_level_percentage = 80;

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_schedule_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoading: false,
          total_collections: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  getPendingSchedules(isLoading) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.status = "pending";
    request_body.tag = "count";

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_schedule_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoading: false,
          total_upcoming_schedules: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  getTotalWasteCollected(isLoading) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.status = "served";

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_totalwaste_collected_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoading: false,
          total_waste_collected: data + " L",
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  getBins() {
    const { user } = this.state;

    let request_body = {
      tag: "count",
      status: "active",
    };

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_bin_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const total_bins = res.data.data;
        this.setState({ total_bins });
      })
      .catch((error) => {
        toastMessage("error", error);
      });
  }

  handlePressCount(route) {
    this.setState({ route });
  }

  render() {
    if (this.state.route !== "") {
      return <Redirect to={this.state.route} />;
    }

    let list_filters = [];

    if (this.props.location) {
      list_filters.push({
        name: "location",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.locations &&
          this.props.filters.locations.length > 0
            ? this.props.filters.locations
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.user_account) {
      list_filters.push({
        name: "user_account",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.users &&
          this.props.filters.users.length > 0
            ? this.props.filters.users
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.type) {
      list_filters.push({
        name: "type",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.types &&
          this.props.filters.types.length > 0
            ? this.props.filters.types
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.level) {
      list_filters.push({
        name: "trash level",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.level_min &&
          this.props.filters.level_max
            ? this.props.filters.level_min +
              "%" +
              " - " +
              this.props.filters.level_max +
              "%"
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.date) {
      list_filters.push({
        name: "date",
        position: "right",
        isDropdown: true,
        selected: [
          moment(this.props.filters.start_date).format("DD-MM-YYYY"),
          moment(this.props.filters.end_date).format("DD-MM-YYYY"),
        ],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    return (
      <div>
        <Header filters={list_filters} />
        <hr />
        {this.props.isCountCard && (
          <div className="row">
            <div
              className="col-6 col-md-3"
              onClick={this.handlePressCount.bind(
                this,
                "/dashboard/schedules/served_schedules"
              )}
            >
              <CardCount
                title="Estimated Total Waste Collection"
                total={this.state.total_waste_collected}
              />
            </div>
            <div
              className="col-6 col-md-3"
              onClick={this.handlePressCount.bind(
                this,
                "/dashboard/bin_devices"
              )}
            >
              <CardCount
                title="Number of Active Smart Bins"
                total={this.state.total_bins}
              />
            </div>
            <div
              className="col-6 col-md-3"
              onClick={this.handlePressCount.bind(
                this,
                "/dashboard/schedules/served_schedules"
              )}
            >
              <CardCount
                title="Total Collection"
                total={this.state.total_collections}
              />
            </div>
            <div
              className="col-6 col-md-3"
              onClick={this.handlePressCount.bind(
                this,
                "/dashboard/schedules/pending_schedules"
              )}
            >
              <CardCount
                title="Upcoming Schedules"
                total={this.state.total_upcoming_schedules}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Home);
