import React from "react";
import "./styles.css";
import GOVLOGO from "../../../assets/gov.png";
import COK from "../../../assets/cok.png";
import HuzaLogo from "../../../assets/HUZALABS.png";
import GIZLogo from "../../../assets/GIZ.png";
import MINICT from "../../../assets/minict.png";
import RISA from "../../../assets/RISA.png";
import Digicenter from "../../../assets/digicenter.png";
import Carousel from "react-grid-carousel";

const Intro = () => {
  const list = [
    { img: MINICT, width: "80%" },
    { img: GIZLogo, width: "90%" },
    { img: COK, width: "30%" },
    { img: RISA, width: "60%" },
    { img: Digicenter, width: "80%" },
    // { img: HuzaLogo },
  ];
  return (
    <div className="partners">
      <div className="container">
        <div>
          <div className="list-partners " style={{ display: "flex" }}>
            <div
              className="col-sm-12 col-md-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h2>
                Partners & <br />
                <span className="text-primary">Stakeholders</span>{" "}
              </h2>
            </div>
            <center className="col-sm-12 col-md-9">
              <div className="partners-list-horz">
                <Carousel
                  cols={5}
                  hideArrow
                  rows={1}
                  gap={0}
                  loop
                  autoplay={4000}
                  responsiveLayout={[
                    {
                      breakpoint: 1200,
                      cols: 5,
                    },
                    {
                      breakpoint: 646,
                      cols: 3,
                      gap: 10,
                      autoplay: 3000,
                      loop: true,
                    },
                    {
                      breakpoint: 500,
                      cols: 2,
                      gap: 10,
                      autoplay: 3000,
                      loop: true,
                    },
                    {
                      breakpoint: 400,
                      cols: 1,
                    },
                    {
                      breakpoint: 300,
                      cols: 1,
                    },
                  ]}
                  mobileBreakpoint={300}
                >
                  {list.map((item, i) => {
                    return (
                      <Carousel.Item>
                        <div
                          className={` partner-logo-container ${
                            i % 2 === 0 ? "even" : "odd"
                          }`}
                          key={i}
                        >
                          <img
                            src={item.img}
                            className="img-fluid"
                            style={item.width && { width: item.width }}
                          />
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
