export const APP_NAME = "cleankigali";
export const REACT_APP_MAPS_API_KEY = "AIzaSyBQGGf1Y_JkJ3sT2oHwEC59Apt0NQVD-Z0";

export const account_roles = {
  admin_account: [
    {
      value: "admin",
      label: "Admin",
    },
  ],
  user_account: [
    {
      value: "admin",
      label: "Admin",
    },
    {
      value: "caretaker",
      label: "Caretaker",
    },
  ],
};

export const statuses = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];

export const availables = [
  {
    label: "true",
    value: "true",
  },
  {
    label: "false",
    value: "false",
  },
];

export const bin_types = [
  {
    label: "Biodegradable",
    value: "Biodegradable",
  },
  {
    label: "Non-Biodegradable",
    value: "Non-Biodegradable",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const bin_status = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Non Active",
    value: "non_active",
  },
];

export const colors = [
  "#39F41F",
  "#F8CF2F",
  "#1FF4AD",
  "#1F9AF4",
  "#C71FF4",
  "#F41FAA",
  "#B9770E",
];
