import React from "react";
import "./styles.css";
import { Input, Textarea } from "../../Input";
import { Button } from "../../Button";
import Fade from "react-reveal/Fade";
import { ENDPOINT } from "../../../constants/api";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";

class ContactUs extends React.Component {
  state = {
    isSubmitting: false,
    error: {},
    name: "",
    email: "",
    message: "",
  };

  onChangeText(name, e) {
    let { error } = this.state;

    delete error[name];

    this.setState({
      [name]: e.target.value,
      error,
    });
  }

  validateForm() {
    const { name, email, message } = this.state;
    let error = this.state.error;

    if (name === "") {
      error.name = "Your name is required";
    }

    if (email === "") {
      error.email = "Your email address is required";
    }

    if (message === "") {
      error.message = "Message is required";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { error, email, message, name } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      const options = {
        method: "POST",
        url: ENDPOINT + "/contact_us",
        data: {
          name,
          email,
          message: message,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            email: "",
            message: "",
            name: "",
          });

          toastMessage(
            "success",
            "Thank you for contact us, our team will respond to you shortly"
          );
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };
  render() {
    return (
      <div className="contactus">
        <div className="container">
          <div className="row justify-content-center">
            <Fade bottom>
              <div className="col-md-5">
                <div className="card" style={{ borderRadius: 20 }}>
                  <div className="card-header">
                    <Fade top>
                      <h2>
                        Get in touch <br />
                        with us
                      </h2>
                    </Fade>
                  </div>
                  <div className="card-body">
                    <Fade bottom>
                      <Input
                        label="Names:"
                        required
                        placeholder=""
                        className="form-control-lg"
                        value={this.state.name}
                        onChange={(e) => this.onChangeText("name", e)}
                        error={this.state.error.name}
                      />
                    </Fade>
                    <Fade bottom>
                      <Input
                        label="Email Address:"
                        required
                        placeholder=""
                        className="form-control-lg"
                        value={this.state.email}
                        onChange={(e) => this.onChangeText("email", e)}
                        error={this.state.error.email}
                      />
                    </Fade>
                    <div className="mb-3">
                      <Fade bottom>
                        <Textarea
                          label="Message:"
                          required
                          placeholder=""
                          className="form-control-lg"
                          value={this.state.message}
                          onChange={(e) => this.onChangeText("message", e)}
                          error={this.state.error.message}
                        />
                      </Fade>
                    </div>
                    <Button
                      text="Submit"
                      isSubmitting={this.state.isSubmitting}
                      className="btn-primary w-100 btn-lg"
                      onPress={this.onSubmit.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
