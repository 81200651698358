import React from "react";
import "./styles.css";
import INTRO_IMG from "../../../assets/intro-system.png";
import { Button } from "../../Button";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import dottedLine from "../../../assets/intro-dotted-line.png";

const Intro = () => {
  return (
    <div className="intro">
      <div className="line-dotted">
        <img src={dottedLine} />
      </div>
      <center className="container">
        <Fade top>
          <h1 className="xlarge-title">
            AI & IOT empowered, <br />
            <span className="text-primary">Smart Waste</span> Collection
          </h1>
        </Fade>

        <div className="intro-btns">
          <Button text="Login" className="btn-primary" />
          {/* <Button text="Download App" className="btn-bordered" /> */}
        </div>

        <div className="intro-system">
          <Fade top>
            <img src={INTRO_IMG} />
          </Fade>
        </div>
      </center>
    </div>
  );
};

export default Intro;
