import React from "react";
import "./styles.css";
import feature_img from "../../../assets/features.png";
import Fade from "react-reveal/Fade";

const Features = () => {
  return (
    <div className="features">
      <div className="container">
        <div className="row gap-5 align-items-center justify-content-center">
          <div className="col-md-5">
            <Fade left>
              <img src={feature_img} className="feature-bg" />
            </Fade>
          </div>
          <div className="col-md-5 justify-content-center">
            <div className="feature-info">
              <Fade top>
                <h2 className="mb-4">
                  Features <span className="text-primary">Covered</span>
                </h2>
              </Fade>
              <Fade top>
                <p className="text-disabled text-p-x">
                  CleanKigali enables the city of kigali to automatically
                  monitor and predict wast levels and collect them ontime using
                  artificial intelligence and internet of things, the main
                  features are:
                </p>
              </Fade>
              <ul>
                <Fade top>
                  <li>
                    <div className="circle">
                      <i className="bx bx-check" />
                    </div>
                    <b>Monitoring of Waste Levels</b>
                  </li>
                </Fade>
                <Fade top>
                  <li>
                    <div className="circle">
                      <i className="bx bx-check" />
                    </div>
                    <b>Automatically Scheduled Waste Collection</b>
                  </li>
                </Fade>
                <Fade top>
                  <li>
                    <div className="circle">
                      <i className="bx bx-check" />
                    </div>
                    <b>Waste gathering and collection analytics</b>
                  </li>
                </Fade>
                <Fade top>
                  <li>
                    <div className="circle">
                      <i className="bx bx-check" />
                    </div>
                    <b>Real time map based insights</b>
                  </li>
                </Fade>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
