import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

//screens
import LoginScreen from "./app/screen/LoginScreen";
import ResetPasswordScreen from "./app/screen/ResetPasswordScreen";
import HomeDashBoardScreen from "./app/screen/HomeDashBoardScreen";
import eWasteMapScreen from "./app/screen/eWasteMapScreen";
import LandingPageScreen from "./app/screen/LandingPageScreen";
import ForgotPasswordScreen from "./app/screen/ForgotPasswordScreen";
import eWasteActivitiesScreen from "./app/screen/eWasteActivitiesScreen";
import AccountScreen from "./app/screen/AccountScreen";
import SensorActivitiesScreen from "./app/screen/SensorActivitiesScreen";
import WasteLevelScreen from "./app/screen/WasteLevelScreen";
import ServedScheduleScreen from "./app/screen/ServedScheduleScreen";
import PendingScheduleScreen from "./app/screen/PendingScheduleScreen";
import AlertWasteBinLevelScreen from "./app/screen/AlertWasteBinLevelScreen";
import AlertSensorStateScreen from "./app/screen/AlertSensorStateScreen";
import IncidentWasteBinLevelScreen from "./app/screen/IncidentWasteBinLevelScreen";
import IncidentSensorStateScreen from "./app/screen/IncidentSensorStateScreen";
import DeviceBinScreen from "./app/screen/DeviceBinScreen";
import NotificationScreen from "./app/screen/NotificationScreen";

const createBrowserHistory = require("history").createBrowserHistory;

function App() {
  return (
    <div className="App">
      <Router history={createBrowserHistory({ queryKey: false })}>
        <Switch>
          <Route exact path="/" component={LandingPageScreen} />
          <Route exact path="/login" component={LoginScreen} />
          <Route
            path="/dashboard"
            render={(props) => {
              const { url } = props.match;
              return (
                <HomeDashBoardScreen {...props}>
                  <Route
                    exact
                    path={`${url}/home/c.waste_map`}
                    component={eWasteMapScreen}
                  />
                  <Route
                    exact
                    path={`${url}/home/c.waste_activities`}
                    component={eWasteActivitiesScreen}
                  />
                  <Route
                    exact
                    path={`${url}/analytics/sensor_activities`}
                    component={SensorActivitiesScreen}
                  />
                  <Route
                    exact
                    path={`${url}/analytics/waste_levels`}
                    component={WasteLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/schedules/served_schedules`}
                    component={ServedScheduleScreen}
                  />
                  <Route
                    exact
                    path={`${url}/alerts/waste_bin levels`}
                    component={AlertWasteBinLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/alerts/waste_bin levels/:bin_id`}
                    component={AlertWasteBinLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/alerts/sensor_state`}
                    component={AlertSensorStateScreen}
                  />
                  <Route
                    exact
                    path={`${url}/incidents/waste_bin levels`}
                    component={IncidentWasteBinLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/incidents/sensor_state`}
                    component={IncidentSensorStateScreen}
                  />
                  <Route
                    exact
                    path={`${url}/schedules/pending_schedules`}
                    component={PendingScheduleScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/bin_devices`}
                    component={DeviceBinScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/accounts`}
                    component={AccountScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/notifications`}
                    component={NotificationScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/notifications/id`}
                    component={NotificationScreen}
                  />
                </HomeDashBoardScreen>
              );
            }}
          />
          <Route
            exact
            path="/forgot_password"
            component={ForgotPasswordScreen}
          />

          <Route
            exact
            path="/reset_password/:username"
            component={ResetPasswordScreen}
          />
        </Switch>
      </Router>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
    </div>
  );
}

export default App;
