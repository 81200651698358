import React from "react";
import "./styles.css";
import download_img from "../../../assets/phone-mockups.png";
import Fade from "react-reveal/Fade";

const Download = () => {
  return (
    <div className="download">
      <div className="container">
        <div className="row gap-2 align-items-center justify-content-center">
          <div className="col-md-6">
            <div className="download-info">
              <Fade top>
                <h2 className="mb-4">
                  Smart City{" "}
                  <span className="text-primary">
                    Waste <br />
                    Collection
                  </span>{" "}
                  App
                </h2>
              </Fade>
              <div style={{ marginBottom: "2.5rem" }}>
                <Fade top>
                  <p
                    className="text-disabled text-p-x download-p"
                    // style={{ width: "60%" }}
                  >
                    Download the app on Google Play or App Store and start
                    enjoying a world-class AI empowered waste collection 
                    management features at the tip of your fingers
                  </p>
                </Fade>
              </div>
              <div className="btn-download">
                <div>
                  <Fade left>
                    <a href="">
                      <img
                        src="https://images.contentstack.io/v3/assets/blt1d89a78b502b83f3/blt398a4a93d9545ed7/6125574a7d73645a601ffaa0/GooglePlay.svg?q=90"
                        className="play img-fluid"
                      />
                    </a>
                  </Fade>
                </div>

                <div>
                  <Fade left>
                    <a href="">
                      <img
                        src="https://images.contentstack.io/v3/assets/blt1d89a78b502b83f3/blt1f32adf9fcc62fa7/612556b8eeb2af56725979c0/AppleAppStore.svg?q=90"
                        className="ios img-fluid"
                      />
                    </a>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 align-items-center justify-content-center">
            <Fade right>
              <img src={download_img} className="download-bg img-fluid" />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
