import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CardCount } from "../../components/CardCount";
import { ENDPOINT } from "../../constants/api";
import { getStorage } from "../../utils/storage";
import toastMessage from "../../utils/toastMessage";
import { LoadingSpinner } from "../LoadingSpinner";
import { Line, Doughnut } from "react-chartjs-2";
import { colors } from "../../constants/strings";

const options = {
  legend: {
    position: "bottom",
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
};

class Analytics extends React.Component {
  state = {
    user: {},
    total_active_bins: 0,
    total_collections: 0,
    total_number_collections: 0,
    route: "",
    total_waste_collected: 0,
    chart: {
      waste_collections: [],
      number_collections: [],
      average: [],
      weekly_trends: [],
      daily_waste_level: [],
      bin_type: [],
    },
    chart_collections: [],
    chart_number_collections: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    await this.getServerSchedules(true);
    await this.getTotalWasteCollected(true);
    await this.getActiveBins(true);
    await this.getTopFiveWasteCollection(true);
    await this.getDailyWasteLevel(true);
    this.getWeeklyTrends(true);
    this.getDailyLevelBinType(true);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      await this.getServerSchedules(true);
      await this.getTotalWasteCollected(true);
      await this.getActiveBins(true);
      await this.getTopFiveWasteCollection(true);
      await this.getDailyWasteLevel(true);
      this.getWeeklyTrends(true);
      this.getDailyLevelBinType(true);
    }
  };

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let request_body = {
      page,
      limit,
    };

    if (filters && filters.locations && filters.locations.length > 0) {
      request_body.sectors = filters.locations;
    }

    if (
      filters &&
      (filters.level_min || filters.level_min === 0) &&
      filters.level_max
    ) {
      request_body.start_level_percentage =
        filters.level_min !== "" ? filters.level_min : undefined;

      request_body.end_level_percentage =
        filters.level_max !== "" ? filters.level_max : undefined;
    }

    if (filters && filters.just_collected) {
      request_body.just_collected = true;
    }

    if (filters && filters.types) {
      request_body.types = filters.types.length > 0 ? filters.types : undefined;
    }

    if (filters && filters.start_date && filters && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }
    return request_body;
  }

  getServerSchedules(isLoadingNumberCollection) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.status = "served";
    request_body.tag = "count";
    request_body.start_level_percentage = 80;

    this.setState({
      isLoadingNumberCollection,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_schedule_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingNumberCollection: false,
          total_number_collections: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingNumberCollection: false });
      });
  }

  getTotalWasteCollected(isLoadingCollected) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.status = "served";

    this.setState({
      isLoadingCollected,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_totalwaste_collected_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingCollected: false,
          total_waste_collected: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingCollected: false });
      });
  }

  getActiveBins(isLoadingActiveBins) {
    const { user } = this.state;
    const { filters } = this.props;

    let request_body = {
      tag: "count",
      status: "active",
    };

    if (filters && filters.types) {
      request_body.types = filters.types.length > 0 ? filters.types : undefined;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_bin_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    this.setState({
      isLoadingActiveBins,
    });

    axios(options)
      .then((res) => {
        const total_active_bins = res.data.data;
        this.setState({ total_active_bins, isLoadingActiveBins: false });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({
          isLoadingActiveBins: false,
        });
      });
  }

  getTopFiveWasteCollection(isLoadingTopCollection) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.status = "served";
    request_body.page = 1;
    request_body.limit = 5;
    request_body.tag = "rank";
    request_body.target = "all";

    this.setState({
      isLoadingTopCollection,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_schedule_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        let chart_data = [],
          labels = [],
          { chart_collections } = this.state;

        for (let i = 0; i < data.length; i++) {
          let total_maxavg_level = Math.round(data[i].total_level, 2);
          let total_count = data[i].count;
          let total_level = total_maxavg_level * total_count;

          if (data[i].bin && data[i].bin.length > 0) {
            labels.push(
              `${data[i].bin[0].address.geolocation} (${data[
                i
              ].bin[0].type.toLowerCase()})`
            );

            chart_collections.push({
              label: data[i].bin[0].bin_id,
              value: total_level,
            });
          }

          chart_data.push(total_level);
        }

        let customLabels = labels.map(
          (label, index) => `${label}: ${chart_data[index]}L`
        );

        this.state.chart.waste_collections = {
          labels: customLabels,
          datasets: [
            {
              data: chart_data,
              backgroundColor: colors,
            },
          ],
        };

        this.setState(
          {
            isLoadingTopCollection: false,
            chart: this.state.chart,
            chart_collections,
          },
          () => {
            this.getTopFiveNumberCollection(true);
          }
        );
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingTopCollection: false });
      });
  }

  getTopFiveNumberCollection(isLoadingNumberCollection) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.status = "served";
    request_body.page = 1;
    request_body.limit = 5;
    request_body.tag = "rank";
    request_body.target = "all";

    this.setState({
      isLoadingNumberCollection,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_schedule_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        let chart_data = [],
          labels = [],
          level_percentages = [],
          { chart_number_collections } = this.state;

        for (let i = 0; i < data.length; i++) {
          if (data[i].bin && data[i].bin.length > 0) {
            labels.push(
              `${data[i].bin[0].address.geolocation} (${data[
                i
              ].bin[0].type.toLowerCase()})`
            );

            chart_number_collections.push({
              label: data[i].bin[0].bin_id,
              value: data[i].count,
            });

            let label = `${data[i].bin[0].address.geolocation} (${data[
              i
            ].bin[0].type.toLowerCase()})`;

            chart_data.push([label, data[i].count]);
            level_percentages.push([label, data[i].level_percentage]);
          }
        }
        chart_data = chart_data.sort((a, b) => b[1] - a[1]);
        chart_data = chart_data.map((el) => el[1]);
        level_percentages = level_percentages.sort((a, b) => b[1] - a[1]);
        level_percentages = level_percentages.map((el) => el[1]);

        let customLabels = labels.map(
          (label, index) => `${label}: ${chart_data[index]}`
        );

        this.state.chart.number_collections = {
          labels: customLabels,
          datasets: [
            {
              data: chart_data,
              backgroundColor: colors,
            },
          ],
        };

        const chart_collections = this.state.chart_collections;

        let average = [],
          average_labels = [];

        for (let i = 0; i < chart_collections.length; i++) {
          let level = chart_collections[i];
          // let number = chart_number_collections[i];

          // let av = Math.round(level.value / number.value);
          let av = level_percentages[i];

          average_labels.push(level.label);
          average.push(av);
        }

        let av_customLabels = labels.map(
          (label, index) => `${label}: ${average[index]}%`
        );

        this.state.chart.average = {
          labels: av_customLabels,
          datasets: [
            {
              data: average,
              backgroundColor: colors,
            },
          ],
        };

        this.setState({
          isLoadingNumberCollection: false,
          chart: this.state.chart,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingNumberCollection: false });
      });
  }

  getWeeklyTrends(isLoadingWeekly) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.status = "served";
    request_body.page = 1;
    request_body.limit = 5;
    request_body.tag = "trend";
    request_body.target = "all";
    request_body.order = "day_of_week";

    this.setState({
      isLoadingWeekly,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_wastelevel_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          let days = {
            7: "Sunday",
            1: "Monday",
            2: "Tuesday",
            3: "Wednesday",
            4: "Thursday",
            5: "Friday",
            6: "Saturday",
          };
          labels.push(days[data[i]._id]);

          chart_data.push(Math.round(data[i].total_level));
        }

        this.state.chart.weekly_trends = {
          labels: labels,
          datasets: [
            {
              label: "Total level per day",
              data: chart_data,
              backgroundColor: "#edffed",
              borderColor: "#05c605",
              radius: 5,
            },
          ],
        };

        this.setState({
          isLoadingWeekly: false,
          chart: this.state.chart,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingWeekly: false });
      });
  }

  handlePressCount(route) {
    this.setState({ route });
  }

  returnChartList(data) {
    return (
      <ul className="chart-list">
        {data &&
          data.map((item, i) => {
            let title = item.split(":");
            return (
              <li key={i}>
                <span className="title">{title[0]}</span>
                <span className="number">{title[1]}</span>
              </li>
            );
          })}
      </ul>
    );
  }

  getDailyWasteLevel(isLoadingDailyWasteLevel) {
    const { user } = this.state;
    const { filters } = this.props;

    this.setState({
      isLoadingDailyWasteLevel,
    });

    let request_body = this.returnFilters();

    request_body.tag = "trend";

    request_body.order = "daily";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_wastelevel_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;

        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          labels.push(data[i]._id);
          chart_data.push(Math.round(data[i].total_level * 100) / 100);
        }

        this.state.chart.daily_waste_level = {
          labels,
          datasets: [
            {
              label: "Levels per day (L)",
              data: chart_data,
              backgroundColor: "#edffed",
              borderColor: "#05c605",
              radius: 5,
            },
          ],
        };

        this.setState({
          isLoadingDailyWasteLevel: false,
          chart: this.state.chart,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingDailyWasteLevel: false });
      });
  }

  getDailyLevelBinType(isLoadingBinType) {
    const { user } = this.state;

    this.setState({
      isLoadingBinType,
    });

    // let request_body = {
    //   tag: "trend",
    //   target: "all",
    //   order:"daily"
    //   order: "bin_type",
    // };

    let request_body = this.returnFilters();

    request_body.tag = "trend";

    request_body.order = "bin_type";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_wastelevel_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;
        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          if (
            data[i]._id !== "Biogradable" &&
            data[i]._id !== "Non-Biogradable"
          ) {
            labels.push(data[i]._id);
            chart_data.push(Math.round(data[i].total_level * 100) / 100);
          }
        }

        let customLabels = labels.map(
          (label, index) => `${label}: (${chart_data[index]}L)`
        );

        this.state.chart.bin_type = {
          labels: customLabels,
          datasets: [
            {
              data: chart_data,
              backgroundColor: colors,
            },
          ],
        };

        this.setState({
          chart: this.state.chart,
          isLoadingBinType: false,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingBinType: false });
      });
  }

  render() {
    if (this.state.route !== "") {
      return <Redirect to={this.state.route} />;
    }

    return (
      <div>
        <div className="row">
          <div className="col-6 col-md-3">
            <CardCount
              title="Estimated Total Waste Collection"
              total={
                this.state.isLoadingCollected
                  ? "..."
                  : this.state.total_waste_collected + " L"
              }
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Total Number of Collection"
              total={
                this.state.isLoadingNumberCollection
                  ? "..."
                  : this.state.total_number_collections
              }
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Total Average Waste per Bin"
              total={
                this.state.total_active_bins === 0
                  ? "..."
                  : `${Math.round(
                      this.state.total_waste_collected /
                        this.state.total_active_bins
                    )}L`
              }
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Average Waste per Collection"
              total={
                this.state.isLoadingNumberCollection &&
                this.state.isLoadingCollected
                  ? "..."
                  : `${Math.round(
                      this.state.total_waste_collected /
                        this.state.total_number_collections
                    )}L`
              }
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Top 5 aggregate waste levels</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingTopCollection ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div style={{ height: 180 }}>
                      <Doughnut
                        data={this.state.chart.waste_collections}
                        options={options}
                      />
                    </div>
                    {this.returnChartList(
                      this.state.chart.waste_collections.labels
                        ? this.state.chart.waste_collections.labels
                        : []
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Top 5 number of collections </h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingNumberCollection ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div style={{ height: 180 }}>
                      <Doughnut
                        data={this.state.chart.number_collections}
                        options={options}
                      />
                    </div>
                    {this.returnChartList(
                      this.state.chart.number_collections.labels
                        ? this.state.chart.number_collections.labels
                        : []
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Top 5 fill level per collection</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingNumberCollection &&
                this.state.isLoadingTopCollection ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div style={{ height: 180 }}>
                      <Doughnut
                        data={this.state.chart.average}
                        options={options}
                      />
                    </div>
                    {this.returnChartList(
                      this.state.chart.average.labels
                        ? this.state.chart.average.labels
                        : []
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-12">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Daily Max Levels</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingDailyWasteLevel ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.daily_waste_level}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-8">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>WeekDay Max Levels</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingTopCollection ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.weekly_trends}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      bezierCurve: true,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Max Level Per Type</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingBinType ? (
                  <LoadingSpinner />
                ) : (
                  <Doughnut
                    data={this.state.chart.bin_type}
                    options={{
                      legend: {
                        position: "bottom",
                        display: true,
                      },
                      maintainAspectRatio: false,
                      responsive: true,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Analytics);
