import React from "react";
import { WasteCollections, Analytics } from "../components/WasteLevels";
import { Home } from "./common";
import { Tabs } from "../components/Tabs";

class WasteLevelScreen extends React.Component {
  renderLevels() {
    return <Analytics />;
  }

  renderCollections() {
    return <WasteCollections />;
  }
  render() {
    return (
      <div>
        <Home date location type level />
        <div>
          <Tabs
            options={[
              {
                title: "Waste Levels",
                data: this.renderLevels(),
              },
              {
                title: "Waste Collections",
                data: this.renderCollections(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default WasteLevelScreen;
