import React from "react";
import "./styles.css";
import Fade from "react-reveal/Fade";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { ENDPOINT } from "../../../constants/api";

class Zone extends React.Component {
  state = {
    list: [],
    limit: 4,
  };

  componentDidMount() {
    this.getZones(true);
  }

  getZones(isLoading) {
    this.setState({ isLoading });

    let options = {
      method: "POST",
      url: ENDPOINT + "/get_regions_covered_info",
      data: {
        available: true,
      },
    };

    axios(options)
      .then((res) => {
        let data = Object.keys(res.data.data);
        let list = [];

        for (let i = 0; i < data.length; i++) {
          list.push({
            location: data[i],
            site: res.data.data[data[i]],
          });
        }
        // console.log(list);
        this.setState({ isLoading: false, list });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        toastMessage("error", error);
      });
  }

  onPressAvailable() {
    let limit = this.state.limit + 4;
    this.setState({
      limit,
    });
  }

  render() {
    return (
      <div className="zone">
        <div className="container">
          <center>
            <Fade top>
              <h2 className="mb-4">
                Regions Covered <br />
                <span className="text-primary"> Summary</span>
              </h2>
            </Fade>
          </center>
          <Fade top>
            <p className="text-disabled text-p-x">
              Various regions covered below have been equiped with an AI &{" "}
              <br />
              IoT empowered waste gathering and collection monitoring
              <br /> system, to enable a world class timely waste management
              process
            </p>
          </Fade>
          <ul>
            {this.state.list.slice(0, this.state.limit).map((item, i) => {
              return (
                <Fade top key={i}>
                  <li>
                    <i className="bx bxs-pin"></i>
                    <span className="location">{item.location}</span>
                    <span className="site-counter">
                      {item.site + " site(s)"}
                    </span>
                  </li>
                </Fade>
              );
            })}
          </ul>
          <center className="available-sites-counter">
            <Fade bottom>
              <a
                href="#zone"
                className="text-p-x"
                onClick={this.onPressAvailable.bind(this)}
              >
                {this.state.list.length} Available Zones
              </a>
            </Fade>
          </center>
        </div>
      </div>
    );
  }
}

export default Zone;
