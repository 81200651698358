import React from "react";
import { Features } from "../components/LandingPage/Features";
import { Intro } from "../components/LandingPage/Intro";
import { ContactUs } from "../components/LandingPage/ContactUs";
import { Zone } from "../components/LandingPage/Zone";
import { Footer } from "../components/LandingPage/Footer";
import { NavBar } from "../components/LandingPage/NavBar";
import { Download } from "../components/LandingPage/Download";
import { Partner } from "../components/LandingPage/Partner";

class LandingPageScreen extends React.Component {
  render() {
    return (
      <div className="app">
        <NavBar />
        <div className="app-containers">
          <section className="section" id="home">
            <Intro />
          </section>
          <section className="section" id="zone">
            <Zone />
          </section>
          <section className="section" id="features">
            <Features />
          </section>
          <section id="download">
            <Download />
          </section>
          <section className="section" id="partners">
            <Partner />
          </section>
          <section className="section" id="contactus">
            <ContactUs />
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}

export default LandingPageScreen;
