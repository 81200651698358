import React from "react";
import { Home } from "./common";
import { Tabs } from "../components/Tabs";
import { Schedules } from "../components/Schedules";

class ServedScheduleScreen extends React.Component {
  renderAll() {
    return <Schedules status="served" />;
  }

  renderServedOnTime() {
    return <Schedules status="served" schedule_status="ontime" />;
  }

  renderServedLate() {
    return <Schedules status="served" schedule_status="late" />;
  }

  renderServedEarly() {
    return <Schedules status="served" schedule_status="early" />;
  }

  render() {
    return (
      <div>
        <Home date location type level />
        <div>
          <Tabs
            options={[
              {
                title: "All",
                data: this.renderAll(),
              },
              {
                title: "Served Early",
                data: this.renderServedEarly(),
              },
              {
                title: "Served on Time",
                data: this.renderServedOnTime(),
              },
              {
                title: "Served Late",
                data: this.renderServedLate(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default ServedScheduleScreen;
