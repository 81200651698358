import moment from "moment";
import React from "react";
import { WasteLevelChart, WasteLevelPerType } from "../components/Chart";
import { HighestLevels } from "../components/HighestLevels";
import { RecentCollections } from "../components/RecentCollections";
import { RecentSchedules } from "../components/RecentSchedules";
import { NonActiveSensors } from "../components/Sensors";
import { Home } from "./common";

class eWasteActivitiesScreen extends React.Component {
  state = {
    fromDate: moment().add(30, "d").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  };
  render() {
    const legend = {
      styles: {
        right: 0,
        top: 0,
        margin: 25,
      },
      items: [
        {
          color: "#7A7D7A",
          icon: "bx-trash-alt",
          name: "Empty",
        },
        {
          color: "#FD0C0C",
          icon: "bx-trash",
          name: "Mid Wast Level",
        },
        {
          color: "#000000",
          icon: "bxs-trash-alt",
          name: "Almost Full Level",
        },
        {
          color: "#0530C6",
          icon: "bxs-trash",
          name: "Just Collected",
        },
      ],
    };
    return (
      <div>
        <Home isCountCard date location type level />
        <div style={{ position: "relative", marginTop: "1rem" }}>
          <div className="row mb-4">
            <div className="col-md-6">
              <WasteLevelChart />
            </div>
            <div className="col-md-6">
              <WasteLevelPerType />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-6">
              <HighestLevels />
            </div>
            <div className="col-md-6">
              <RecentCollections />
            </div>
          </div>
          {/* <div className="row mb-4">
            <div className="col-md-6">
              <RecentSchedules />
            </div>
            <div className="col-md-6">
              <NonActiveSensors />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default eWasteActivitiesScreen;
