import React from "react";
import "./styles.css";
import LOGO from "../../../assets/logo.png";
import Button from "../../Button/Button";

class NavBar extends React.Component {
  state = {
    active_menu: "home",
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll.bind(this), {
      passive: true,
    });
  }

  onScroll() {
    const scrollY = window.pageYOffset;
    let active_menu = this.state.active_menu;

    if (scrollY >= 0 && scrollY < 488) {
      active_menu = "home";
    } else if (scrollY >= 488 && scrollY < 1324) {
      active_menu = "zone";
    }
    if (scrollY >= 1324 && scrollY < 1856) {
      active_menu = "features";
    }
    if (scrollY >= 1856 && scrollY < 2237) {
      active_menu = "download";
    }

    if (scrollY >= 2237 && scrollY < 2778) {
      active_menu = "partners";
    }

    if (scrollY >= 2778 && scrollY < 2978) {
      active_menu = "contact us";
    }

    this.setState({ active_menu });

    const navbar = document.getElementById("navbar");

    let classList;

    if (!navbar) return;
    classList = navbar.classList;

    if (scrollY > 100 && !classList.contains("fixed-top")) {
      classList.add("fixed-top");
    } else if (classList.contains("fixed-top") && scrollY == 0) {
      classList.remove("fixed-top");
    }
  }
  render() {
    const menus = [
      {
        name: "Home",
        route: "/#home",
      },
      {
        name: "Zone",
        route: "/#zone",
      },
      {
        name: "Features",
        route: "/#features",
      },
      {
        name: "Download",
        route: "/#download",
      },
      {
        name: "Partners",
        route: "/#partners",
      },

      {
        name: "Contact Us",
        route: "/#contactus",
      },
    ];

    return (
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light"
        id="navbar"
      >
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={LOGO} />
            <span>
              Clean<b>Kigali</b>
            </span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {menus.map((menu, m) => {
                return (
                  <li key={m} className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.active_menu === menu.name.toLowerCase() &&
                        "active"
                      }`}
                      href={menu.route}
                    >
                      {menu.name}
                    </a>
                  </li>
                );
              })}
            </ul>
            <Button
              onPress={() => {
                window.location.href = "/login";
              }}
              text="Login"
              className="btn-primary"
            />
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
